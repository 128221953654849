<template>
  <div
    id="kt_header_mobile"
    class="kt-header-mobile"
    v-bind:class="headerClasses"
  >
    <div class="kt-header-mobile__logo">
      <router-link
        :to="{
          name: 'purchase-target-list'
        }"
      >
        <img :src="siteLogo" alt="" />
      </router-link>
    </div>

    <div class="kt-header-mobile__toolbar">
      <div class="store-name">{{ currentUser.name }}</div>
      <button
        class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
        id="kt_aside_mobile_toggler"
      >
        <span></span>
      </button>

      <!-- <button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler">
        <span></span>
      </button>

      <button
        class="kt-header-mobile__topbar-toggler"
        id="kt_header_mobile_topbar_toggler"
        ref="kt_header_mobile_topbar_toggler"
      >
        <i class="flaticon-more"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTToggle from '@/assets/js/toggle.js';
import objectPath from 'object-path';

export default {
  name: 'KTHeaderMobile',
  components: {},
  mounted() {
    new KTToggle(this.$refs['kt_header_mobile_topbar_toggler'], {
      target: 'body',
      targetState: 'kt-header__topbar--mobile-on',
      togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
    });
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),
    ...mapGetters(['currentUser']),
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig('self.logo.dark');
    },

    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig('brand.self.skin');
      // set brand logo
      const logoObject = this.layoutConfig('self.logo');
      console.log(logoObject);
      let logo;
      if (typeof logoObject === 'string') {
        logo = logoObject;
      }
      if (typeof logoObject === 'object') {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + '');
      }
      if (typeof logo === 'undefined') {
        const logos = this.layoutConfig('self.logo');
        logo = logos[Object.keys(logos)[0]];
      }
      return require(`@/assets/media/logos/${logo}`);
    },
    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header_mobile');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    }
  }
};
</script>
<style scoped>
.store-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  color: #c6cfda;
}
</style>
